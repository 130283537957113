/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React from 'react';
import Layout from '../components/Layout';
import Subheader from '../components/Subheader';
import SEO from '../components/seo';

interface Props {
  pageContext: {
    html: string;
    crumbs: {
      slug: string;
      display: string;
    }[];
    name: string;
    display: string;
  };
}

export class Template extends React.Component<Props> {
  componentDidMount() {
    const toggle = document.getElementById('toggle');
    if (!toggle) {
      return;
    }

    const details = document.getElementsByTagName('details');

    const onClickToggle = () => {
      toggle.classList.toggle('expanded');
      toggle.classList.toggle('collapsed');

      if (toggle.classList.contains('expanded')) {
        for (let i = 0; i < details.length; i += 1) {
          details[i].setAttribute('open', '');
        }
      } else {
        for (let i = 0; i < details.length; i += 1) {
          details[i].removeAttribute('open');
        }
      }
    };

    toggle.addEventListener('click', onClickToggle, false);
  }

  render() {
    const { pageContext } = this.props;

    const pageTitle = pageContext.display || pageContext.name;
    console.log('pageContext:', pageContext);
    return (
      <div>
        <Layout>
          <SEO title={pageTitle} />
          <Subheader crumbs={pageContext.crumbs} />
          <div dangerouslySetInnerHTML={{ __html: pageContext.html }} />
        </Layout>
      </div>
    );
  }
}

export default Template;
